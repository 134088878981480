import { graphql } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout/layout";
import {
  getBlock,
  getSectionClassesWithoutPaddingTop,
} from "../utils/block-util";
import { getPageSlugs } from "../utils/page-slug-util";
import { useForm } from "react-hook-form";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SEO from "../seo/seo";

export function Head({ data, pageContext }) {
  return (
    <SEO
      seo={data.datoCmsProtectedPage.seoMetaTags}
      language={pageContext.language}
      pageSlug=""
    ></SEO>
  );
}

export default function ProtectedPageModel({ data }) {
  const { t } = useTranslation();
  const protectedPage = data.datoCmsProtectedPage;
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
  const [checkingPassword, setCheckingPassword] = useState(false);
  const [showIncorrectPasswordLabel, setShowIncorrectPasswordLabel] =
    useState(false);
  const [password, setPassword] = useState("");

  const { handleSubmit } = useForm({
    mode: "onChange",
  });

  async function onSubmit() {
    setCheckingPassword(true);
    var formData = new FormData();
    formData.append("pageSlug", protectedPage.pageSlug);
    formData.append("password", btoa(password));

    await window
      .fetch(`/api/securepage-checker`, {
        method: `POST`,
        body: formData,
      })
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          setPasswordIsCorrect(true);
        } else {
          setShowIncorrectPasswordLabel(true);
        }
      })
      .finally(() => setCheckingPassword(false));
  }

  return passwordIsCorrect ? (
    <Layout pageSlugs={getPageSlugs(data)}>
      <main role="main" className="py-6">
        {protectedPage.content.blocks.map((block, index) => {
          return block.heroImage ? (
            getBlock(block)
          ) : (
            <section
              role="region"
              className={getSectionClassesWithoutPaddingTop(
                protectedPage.content.blocks,
                index
              )}
              key={block.id}
            >
              {getBlock(block)}
            </section>
          );
        })}
      </main>
    </Layout>
  ) : (
    <div className="passwordprompt-page">
      <div className="passwordprompt">
        <div>
          <h1>{t("aca-group-protected-page")}</h1>
          <p>{t("please-fill-in-your-password")}</p>
        </div>
        <hr className="passwordprompt-hr" />
        <form
          id="passwordprompt-form"
          role="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="password"
            name="password"
            placeholder={t("password")}
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
          />
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={checkingPassword}
            className={`btn px-4 font-weight-600 color-white hover-color-white ${
              checkingPassword ? "bg-gray-500" : "bg-primary-500"
            } hover-bg-quaternary-500 focus-border-quaternary-500 transition border-radius-pill`}
          >
            {t("view-page")}
          </button>
          <p
            className={`${
              showIncorrectPasswordLabel ? "" : "d-none"
            } mt-4 color-tertiary-400`}
          >
            {t("incorrect-password")}
          </p>
        </form>
      </div>
    </div>
  );
}

export const query = graphql`
  query ($language: String!, $originalId: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsProtectedPage(originalId: { eq: $originalId }, locale: $language) {
      id
      title
      pageSlug
      content {
        blocks {
          ...titleBlock
          ...blocksWithIconsFour
          ...richText
          ...blocksWithIconsThree
          ...blocksWithImagesThree
          ...blocksWithIconsTwo
          ...quote
          ...author
          ...image
          ...contactPerson
          ...blockWithIconAndImage
          ...logosBlock
          ...separator
          ...visualSummary
          ...code
          ...button1Link
          ...button2Link
          ...blocksWithIconsTwoAndImageBlock
          ...processVisualization
          ...linkToService
          ...linkToModel3
          ...linkToModel1
          ...executableHtml
          ...hubspotForm
        }
      }
      meta {
        createdAt
      }
      seoMetaTags(locale: $language) {
        tags
      }
    }
    pageSlugs: datoCmsProtectedPage(originalId: { eq: $originalId }) {
      _allPageSlugLocales {
        locale
        value
      }
    }
  }
`;
